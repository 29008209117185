import { defineStore } from "pinia";

export const useCursorStore = defineStore("cursor", {
  state: () => ({
    wording: "",
    isVisible: false,
  }),
  getters: {
    getCurrentWording: (state) => state.wording,
    isActive: (state) => state.isVisible,
    cursorState: (state) => ({
      wording: state.wording,
      isVisible: state.isVisible,
    }),
  },
  actions: {
    showCursor(newWording) {
      this.wording = newWording;
      this.isVisible = true;
    },
    hideCursor() {
      this.isVisible = false;
    },
  },
});
